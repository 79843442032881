<template>
	
	<div class="section_right main">
		
		<div class="section_right_inner">
			<div class="background">
				<!-- <a :href="`https://bscscan.com/address/${escrow_address}#tokentxns`" target="_new"> -->
					<!-- <div class="asset_box mb-4">
						
						<div class="w-100">
							<div class="flex-between-center">
								<div>{{$t("dashboard.100")}}</div>
							</div>
							<div class="flex justify-content-between align-items-center">
								<div class="txt_box d-flex flex-wrap break-all align-items-center">{{numberWithCommas(escrow_balance)}}</div>
								<div class="icon opacity-25"><i class="fas fa-lg fa-coins"></i></div>
							</div>
						</div>
					</div> -->
				<!-- </a> -->
				<div class="asset_box flex justify-content-between mb-3">
					<div @click="GotoHistory('SalesList')">
						<div>{{$t("dashboard.1")}}</div>
						<div class="flex justify-content-between align-items-center">
							<div class="txt_box d-flex flex-wrap break-all align-items-center">{{numberWithCommas(my_sales)}}<small>({{numberWithCommas(my_sales_hold)}})</small></div>
							<div class="icon"><i class="fas fa-calculator"></i></div>
						</div>
					</div>
					<div @click="GotoHistory('BonusHistory')">
						<div>{{$t("dashboard.2")}}</div>
						<div class="flex justify-content-between align-items-center">
							<div class="txt_box d-flex flex-wrap break-all align-items-center"> {{numberWithCommas(my_extra)}}</div>
							<div class="icon"><i class="fas fa-hand-holding-usd"></i></div>
						</div>
					</div>
				</div>
				<div class="asset_box flex justify-content-between mt-1 mb-3">
					<div  @click="GotoHistory('NccAssetsList')">
						<div>{{$t("dashboard.3")}} COIN</div>
						<div class="flex justify-content-between align-items-center">
							<div class="txt_box d-flex flex-wrap break-all align-items-center">{{numberWithCommas(my_coin)}}</div>
							<div class="icon"><i class="fas fa-coins"></i></div>
						</div>
					</div>
					<div @click="GotoHistory('AssetsHistory')">
						<div>{{$t("dashboard.4")}}</div>
						<div class="flex justify-content-between align-items-center">
							<div class="txt_box d-flex flex-wrap break-all align-items-center">{{numberWithCommas(my_assest)}}</div>
							<div class="icon"><i class="fas fa-sack-dollar"></i></div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="link_form flex">
					<div class="sponsorship_box mb-3">
						<apexchart height="230" type="donut" :options="pie_options" :series="pie_series"></apexchart>
					</div>
					<div class="sponsorship_box mb-3" >
						<small class="more_badge" @click="GotoHistory('BonusHistory')">more</small>
						<apexchart height="230" type="pie" :options="donut_options" :series="donut_series"></apexchart>
					</div>
				</div>
				<div class="link_form line_form flex">
					<div class="sponsorship_box mb-3">
						<p class="p01">{{$t("dashboard.5")}}</p>
						<p class="p02">{{numberWithCommas(l_cnt)}}</p>
						<p class="p03">{{numberWithCommas(l_sum)}} (<small>{{numberWithCommas(l_today)}})</small></p>
						<button class="btn btn-secondary position-relative mt-3 d-flex justify-content-around" :class="{'check_on' : line=='L'}"  @click="RecomUrl('L')">
							<!-- <p class="p01">{{$t("dashboard.20")}}</p> -->
								
								<div class="d-flex flex-column align-items-start flex-grow-1">
									<small class="text-start fs-px-11">{{$t("dashboard.20")}}</small>
									{{ $t("dashboard.7") }}
								</div>
								<i class="far fa-link text-white rounded-circle copy-icon fs-px-10 "></i>
						</button>
					</div>
					<div class="sponsorship_box mb-3">
						<p class="p01">{{$t("dashboard.6")}}</p>
						<p class="p02">{{numberWithCommas(r_cnt)}}</p>
						<p class="p03">{{numberWithCommas(r_sum)}} (<small>{{numberWithCommas(r_today)}})</small></p>
						<button class="btn btn-secondary position-relative  mt-3"  :class="{'check_on' : line=='R'}" @click="RecomUrl('R')">
							<!-- <p class="p01">{{$t("dashboard.21")}}</p> -->
						
							<div class="d-flex flex-column align-items-start flex-grow-1">
									<small class="text-start fs-px-11">{{$t("dashboard.21")}}</small>
									{{ $t("dashboard.7") }}
								</div>
								<i class="far fa-link text-white rounded-circle copy-icon fs-px-10 "></i>
						</button>
					</div>
				</div>
			</div>
			<input type="text" class="link_input" v-model="link" name="recaddr" id="recaddr" style="display:none">
			
		</div>
		<Popup ref="popup" />
	</div>
</template>
<script>
const CryptoJS = require("crypto-js");
import Popup from '@/components/common/Popup.vue'
	export default {
		components: {
			Popup
		},
		data(){
			return{
				site : '',
				ppOpen : false,
				id : this.$store.state.id,
				name : this.$store.state.name,
				level : '',
				l_cnt : 0,
				l_sum : 0,
				l_today : 0,
				r_cnt : 0,
				r_sum :0,
				r_today : 0,
				my_sales : 0,//내 구매금액
				my_sales_hold : 0,//내 구매금액
				my_extra : 0,//내 수당
				my_coin : 0,
				my_assest : 0,
				left_amount : 0,//왼쪽 라인 금액
				right_amount : 0,//오른쪽 라인 금액
				line : '',
				link :'',
				line_info : [],
				pie_options: {
					labels: [this.$t('dashboard.22'),this.$t('dashboard.23')],
					chart: {
						width: '100%',
						type: 'pie',
					},
					legend: {
						position: 'bottom',
					},
					colors: ['#6565e6', '#a50ff5', '#80f1cb', '#00E396', '#11E311', '#005596'],
					stroke: {
						show: false
					},
					dataLabels: {
						style: {
							fontFamily: 'Roboto',
							fontSize: '11px', // 글자 크기
							fontWeight: '300', // 글자 굵기
						},
						
						formatter(val) {
							return val.toFixed(0) + '%'
						},
					}

				},
				
				pie_series: [0,0],

				donut_options: {
					// labels: ['추천','팀','매칭','직급','센터'],
					labels:[this.$t('dashboard.11'),this.$t('dashboard.25'),this.$t('dashboard.16'),this.$t('dashboard.17'),this.$t('dashboard.26')],
					legend: {
						position: 'bottom'
					},
					colors: ['#80c7fd', '#008FFB', '#4dd5dd', '#ce8aef', '#a50ff5', '#6565e6'],
					stroke: {
						show: false
					},
					
					plotOptions: {
						pie: {
							dataLabels: {
							offset: -5
							}
						}
					},
					dataLabels: {
						// enabled:false,
						offsetY:10,
						style: {
							fontFamily: 'Roboto',
							fontSize: '10px', // 글자 크기
							fontWeight: '300', // 글자 굵기
							textShadow: '0 0 black'
						},
						formatter(val) {
							return val.toFixed(0) + '%'
						},
					}
				},
				donut_series: [20,20,20,20,20],
				escrow_balance : '',
				escrow_address : '',
			}
		},
		mounted(){
			this.GetLineInfo()
			this.GetMySales()
			this.GetMyCoin();
			this.GetMyAssest();
			this.GetMyExtra()
			this.GetExtraChart();
			this.GetBonusChart();
			
			this.GetNoticePopup();

			this.GetEscrow()
			this.CheckTerm();
			
		},
		methods : {
			GotoHistory(target){
				this.$router.push({path : `/${this.$i18n.locale}/${target}`})
			},
			RecomUrl(line){
				const id = this.id;
				const site = process.env.VUE_APP_FRONT_URL;
				this.link = `${site}/${this.$i18n.locale}/sign/${id}/${line}`;
				
				this.$copyText(this.link).then(()=> {
					alert(this.$t('deposit.8'))
				}	
				)
			},
			CheckLeftLine(){
				const line = this.line;
				if(line =='L'){
					return 'active_line'
				}
			},
			CheckRightLine(){
				const line = this.line;
				if(line =='R'){
					return 'active_line'
				}
			},
			GetEscrow(){
				this.$http.post(`/member/dashboard/GetEscrow`,{}).then(

					res => {
						if(res.status==200){

							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.escrow_balance = body.balance;
								this.escrow_address = body.address;
							}else if(res.data.code=='9999'){
								// 
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			CheckTerm(){
				this.$http.post(`/member/common/CheckTermAgree`,{}).then(

					res => {
						if(res.status==200){

							if(res.data.code=='100'){
								this.$router.push({path : `/${this.$i18n.locale}/term`});
							}else if(res.data.code=='9999'){
								// 
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			GetNoticePopup(){
				const lang = this.$i18n.locale
				
				const body = {lang};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/dashboard/GetNoticePopup`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								
								this.$refs.popup.code = body.info.code;
								this.$refs.popup.title = body.info.title;
								this.$refs.popup.content = body.info.memo;
								this.$refs.popup.ppShow = true;
							}else if(res.data.code=='9999'){
								// 
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			GetMySales(){
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/dashboard/GetMySales`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								
								
								this.my_sales = body.info.point;
								this.my_sales_hold = body.info.hold;
							}else if(res.data.code=='9999'){
								// 
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			GetMyCoin(){
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/dashboard/GetMyCoin`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								
								this.my_coin = body.info.point;
							}else if(res.data.code=='9999'){
								// 
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			GetMyAssest(){
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/dashboard/GetMyAssets`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								
								
								this.my_assest = body.info.point;
							}else if(res.data.code=='9999'){
								// 
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			GetExtraChart(){
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/dashboard/GetExtraChart`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								
								
								
								const left = body.left;
								const extra = body.extra;
								this.pie_series = [extra,left];
							}else if(res.data.code=='9999'){
								// 
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			GetBonusChart(){
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/dashboard/GetBonusChart`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								
								
								const recom = body.recom;
								const support = body.support;
								const match = body.match;
								const star = body.star;
								const center = body.center;
								this.donut_series = [recom,support,match,star,center]
							}else if(res.data.code=='9999'){
								// 
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			GetMyExtra(){
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/dashboard/GetMyExtra`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								
								this.my_extra = body.info.point;
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			GetLineInfo(){
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/dashboard/GetLineInfo`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								for (const el of body.line) {
									const line = el.line;
									const count = el.count;
									const point = el.point;
									const today  = el.today;
									
									if(line =='L'){
										this.l_cnt = count;
										this.l_sum = point;
										this.l_today = today;
									}else if(line =='R'){
										this.r_cnt = count;
										this.r_sum = point;
										this.r_today = today;
									}
								}
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			numberWithCommas(x) {
				var parts=x.toString().split(".");
				return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
			},
			GotoMove(path){
				const link = `/${this.$i18n.locale}/${path}`
				this.$router.push({path:link})

			}
		},
		
	}
	/*
	
			<div class="main_section03">
				<!--main_section03 -->
				<div class="graph_01">
					<!--직급 레벨표시-->
					<p class="table_title">
						<i class="level_icon3 far fa-info-square"></i>{{$t('dashboard.5')}}</p>
					<div id="m_nav_line"></div>
					<div class="link_form">
						// <input type="text" class="link_input" :value='`${site}${$i18n.locale}/sign?userid=${id}`' name="recaddr" id="recaddr">
						<span class="copy_btn" onclick="copy_to_clipboard()">{{$t('dashboard.6')}}</span>
					</div>
					
				</div>
				<div class="graph_01">
					<!--Allowance-->
					<p class="table_title">
						<i class="level_icon3 far fa-abacus"></i>{{$t('dashboard.10')}}</p>
					<div id="m_nav_line"></div>
					<div class="bonus_div">
						<p class="title title_01">{{$t('dashboard.11')}}</p>
						<p class="total">{{numberWithCommas(recom)}} P</p>
					</div>
					<div id="m_nav_line"></div>
					<div class="bonus_div">
						<p class="title title_02">{{$t('dashboard.12')}}</p>
						<p class="total">{{numberWithCommas(support)}} P</p>
					</div>
					<div id="m_nav_line"></div>
					<div class="bonus_div">
						<p class="title title_03">{{$t('dashboard.13')}}</p>
						<p class="total">{{numberWithCommas(recom_map)}} P</p>
					</div>
					<div id="m_nav_line"></div>
					<div class="bonus_div">
						<p class="title title_04">{{$t('dashboard.29')}}</p>
						<p class="total">{{numberWithCommas(class_p)}} P</p>
					</div>
				</div>
				<div class="graph_01">
					<p class="table_title">
						<i class="level_icon3 far fa-usd-circle"></i>
						{{$t('dashboard.9')}}</p>
					<div id="m_nav_line"></div>
					<div class="bonus_div">
						<p class="title title_04">{{$t('dashboard.14')}}</p>
						<p class="total">$ {{numberWithCommas(eth_usd)}}</p>
					</div>
					<div id="m_nav_line"></div>
					<div id="m_nav_line"></div>
					
				</div>
				<!--직급 레벨표시 END-->
				<div class="graph_01 mr-0">
					<!--레벨그래프-->
					<p class="table_title">
						<i class="level_icon3 far fa-chart-line"></i>{{$t('dashboard.7')}}</p>
					<div id="m_nav_line"></div>
					<div class="overflow-y">
						<div class="circle_box">
							<div class="circle circle01">	
								<strong class="circle_strong"></strong>
								<span class="circle_title">{{$t('dashboard.8')}}</span>
							</div>
						</div>
					</div>
					<!-- <div id="container"></div> -->
					<!-- / 레벨그래프 end -->
				</div>
				<div class="sales_list">
					<!--Sales list-->
					<p class="table_title2">{{$t('dashboard.24')}}<span class="more"><router-link :to="`/${$i18n.locale}/SalesList`">{{$t('dashboard.17')}}</router-link></span>
					</p>
					<div id="m_nav_line"></div>
					<table style="width:100%" class="main_table05">
						<thead>
							<tr>
								<th>{{$t('dashboard.3')}}</th>
								<th>{{$t('dashboard.23')}}</th>
								<th>{{$t('dashboard.20')}}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in packageList" :key="'package'+index">
								<td>{{numberWithCommas(item.target_point)}} {{item.target}}</td>
								<td>{{numberWithCommas(item.point)}}</td>
								<td>{{$moment(item.create_time).format('YYYY-MM-DD HH:mm:ss')}}</td>
							</tr>
						</tbody>
						<tbody></tbody>
					</table>
				</div>
				<div class="notice_table01">
					<p class="table_title2">{{$t('dashboard.16')}}<span class="more"><router-link :to="`/${$i18n.locale}/notice`">{{$t('dashboard.17')}}</router-link></span>
					</p>
					<div id="m_nav_line"></div>
					<table style="width:100%" class="main_table06">
						<thead>
							<tr>
								<th>{{$t('dashboard.18')}}</th>
								<th>{{$t('dashboard.19')}}</th>
								<th>{{$t('dashboard.20')}}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in noticeList" :key="'notice'+index" @click="GotoNotice(index)">
								<td>{{item.title}}</td>
								<td>{{item.writer}}</td>
								<td>{{item.date}}</td>
							</tr>
						</tbody>
						<tbody></tbody>
					</table>
				</div>
				<!--notice_table end-->
				<!-- <div class="recommendation_table01">
					<p class="table_title2">{{$t('dashboard.21')}}<span class="more"><router-link :to="`/${$i18n.locale}/ReferralHistory`">{{$t('dashboard.17')}}</router-link></span>
					</p>
					<div id="m_nav_line"></div>
					<table style="width:100%" class="main_table04">
						<thead>
							<tr>
								<th>{{$t('dashboard.22')}}</th>
								<th>{{$t('dashboard.20')}}</th>
								<th>{{$t('dashboard.23')}}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in referralList" :key="'referral'+index">
								<td>{{item.id}}</td>
								<td>{{item.date}}</td>
								<td>{{numberWithCommas(item.point)}}</td>
							</tr>
						</tbody>
						<tbody></tbody>
					</table>
				</div> -->
				<!--Sales list end-->
			</div>
			<!--section_right inner end-->
	*/
</script>
<style scoped>
	.main_balance_total span {
		display: inline-block;
		font-size: 24px;
	}
	.span001{
		background: transparent;
		padding-left: 0px;
		font-size: 14px;
		margin-left: 7px;
	}
	.red {
		color: red;
	}
	.blue{
		color: blue;
	}

	.active_line{
		background-color: yellow !important;
		color: #333;
	}
	.sponsorship_box:nth-child(2) {
		position: relative;
	}
	.more_badge{
		position: absolute;
		top: 10px;
		right: 10px;
		font-size: 10px;
		background-color: #eee;
		padding: 4px 6px;
		border-radius: 6px;
		z-index: 9;
	}
	.theme01 .more_badge{
		background-color: #333;
	}

	.copy-icon{
		background-color: #6565e6;
		padding: 8px;
		position: absolute;
		opacity: .5;
		/* top: 50%;
		transform: translateY(-50%); */
		/* left: 5px; */
		right: 5px;
		bottom: 10px;
		
	}
</style>
<style>
.apexcharts-legend {
	max-height: 30px !important;
}
.apexcharts-text.apexcharts-pie-label{
	text-shadow: none !important;
	filter: drop-shadow(0);
}
</style>